import React from "react";
import MainLayout from "@/layouts/MainLayout";
import { Seo } from "@/components/Seo";
import { COLORS, TYPOGRAPHY } from "@/constants";
import { Container } from "@/components/Container";
import styled from "styled-components";
import { graphql } from "gatsby";

const Policy = () => {
  const seoData = {
    metaDescription: "Medico Surveys landing",
    metaTitle: "Medico Surveys",
    keywords: "policy",
  };

  return (
    <MainLayout>
      <Seo data={seoData} />
      <Root>
        <StyledContainer>
          <Title>
            Политика обработки <br />
            персональных данных
          </Title>

          <Heading3>I. Общие положения</Heading3>
          <p>
            1. Настоящая Политика (далее «Политика») разработана на основании
            законодательства Российской Федерации, в частности, Конституции РФ,
            ТК РФ, ГК РФ, ФЗ «О персональных данных».
          </p>

          <p>
            2. Политика определяет совокупность норм и принципов,
            регламентирующих действия по обработке и защите персональных данных,
            со стороны ООО «Эйч Ар Эс Групп» (далее «Общество»).
          </p>

          <p>
            3. В обязанности Общества входит обеспечение неограниченного доступа
            к Политике.
          </p>

          <p>4. Понятия и определения:</p>

          <p>
            <b>Персональные данные</b>— любая информация, относящаяся к прямо
            или косвенно определенному или определяемому физическому лицу
            (субъекту персональных данных);
          </p>

          <p>
            <b>Специальные категории персональных данных</b> — персональные
            данные субъектов персональных данных, касающиеся расовой,
            национальной принадлежности, политических взглядов, религиозных или
            философских убеждений, состояния здоровья, интимной жизни и
            судимости;
          </p>

          <p>
            <b>Биометрические персональные данные</b> — сведения, которые
            характеризуют физиологические и биологические особенности человека,
            на основании которых можно установить его личность и которые
            используются оператором для установления личности субъекта
            персональных данных;
          </p>

          <p>
            <b>Оператор</b> — государственный орган, муниципальный орган,
            юридическое или физическое лицо, самостоятельно или совместно с
            другими лицами организующие и (или) осуществляющие обработку
            персональных данных, а также определяющие цели обработки
            персональных данных, состав персональных данных, подлежащих
            обработке, действия (операции), совершаемые с персональными данными;
          </p>

          <p>
            <b>Обработка персональных данных</b> — любое действие (операция) или
            совокупность действий (операций), совершаемых с использованием
            средств автоматизации или без использования таких средств с
            персональными данными, включая сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных
            данных;
          </p>

          <p>
            <b>Автоматизированная обработка персональных данных</b> — обработка
            персональных данных с помощью средств вычислительной техники;
          </p>

          <p>
            <b>Предоставление персональных данных</b> — действия, направленные
            на раскрытие персональных данных определенному лицу или
            определенному кругу лиц;
          </p>

          <p>
            <b>Блокирование персональных данных</b> — временное прекращение
            обработки персональных данных (за исключением случаев, если
            обработка необходима для уточнения персональных данных);
          </p>

          <p>
            <b>Уничтожение персональных данных</b> — действия, в результате
            которых становится невозможным восстановить содержание персональных
            данных в информационной системе персональных данных и (или) в
            результате которых уничтожаются материальные носители персональных
            данных;
          </p>

          <p>
            <b>Информационная система персональных данных</b> — совокупность
            содержащихся в базах данных персональных данных и обеспечивающих их
            обработку информационных технологий и технических средств;
          </p>

          <p>
            <b>Трансграничная передача персональных данных</b> — передача
            персональных данных на территорию иностранного государства органу
            власти иностранного государства, иностранному физическому лицу или
            иностранному юридическому лицу;
          </p>

          <p>
            <b>Пользователь услуг Общества</b> — юридическое или физическое
            лицо, пользующееся услугами Общества;
          </p>

          <p>
            <b>Конфиденциальность персональных данных</b> — обязательное для
            исполнения оператором требование не раскрывать третьим лицам и не
            распространять персональные данные без согласия субъекта
            персональных данных, если иное не предусмотрено федеральным законом;
          </p>

          <p>
            5. Персональные данные субъектов персональных данных относятся к
            конфиденциальной информации, за исключением сведений, подлежащих
            распространению в СМИ в установленных федеральными законами случаях.
          </p>

          <Heading3>II. Цели обработки персональных данных Обществом</Heading3>

          <p>
            6. Обработка персональных данных субъектов персональных данных
            осуществляется в целях:
          </p>
          <p>* исполнения положений законодательства РФ;</p>
          <p>
            * принятия решения о трудоустройстве кандидата на работу в Общество
            или пользователя услуг Общества;
          </p>
          <p>
            * заключения и выполнения обязательств по трудовым договорам,
            договорам негосударственного пенсионного обеспечения, договорам об
            обязательном пенсионном страховании;
          </p>
          <p>
            * заключения и выполнения обязательств по гражданско-правовым
            договорам на оказание услуг, оказываемых Обществом.
          </p>

          <Heading3>
            III. Условия и принципы обработки персональных данных
          </Heading3>
          <p>
            7. Обработка персональных данных в Обществе учитывает необходимость
            обеспечения защиты прав и свобод субъектов персональных данных, в
            том числе защиты права на неприкосновенность частной жизни, личную и
            семейную тайны и основана на следующих принципах:
          </p>
          <p>
            * обработка персональных данных должна осуществляться на законной и
            справедливой основе;
          </p>
          <p>
            * обработка персональных данных должна ограничиваться достижением
            конкретных, заранее определенных и законных целей. Не допускается
            обработка персональных данных, несовместимая с целями их сбора;
          </p>
          <p>
            * не допускается объединение баз данных, содержащих персональные
            данные, обработка которых осуществляется в целях, несовместимых
            между собой;
          </p>
          <p>
            * обработке подлежат только те персональные данные, которые отвечают
            целям обработки;
          </p>
          <p>
            * содержание и объем обрабатываемых персональных данных должны
            соответствовать заявленным целям обработки. Обрабатываемые
            персональные данные не должны быть избыточными по отношению к
            заявленным целям их обработки;
          </p>

          <p>
            * при обработке персональных данных должны быть обеспечены точность
            и достаточность персональных данных, а в необходимых случаях и
            актуальность по отношению к целям обработки. Общество должно
            принимать необходимые меры по удалению или уточнению неполных или
            неточных данных либо обеспечивать принятие таких мер;
          </p>
          <p>
            * хранение персональных данных должно осуществляться в форме,
            позволяющей определить cубъекта персональных данных, не дольше, чем
            того требуют цели обработки персональных данных, если срок хранения
            персональных данных не установлен федеральным законом, договором,
            стороной которого, выгодоприобретателем или поручителем по которому
            является субъект персональных данных;
          </p>
          <p>
            * обрабатываемые персональные данные должны быть уничтожены либо
            обезличены по достижении целей обработки или в случае утраты
            необходимости в достижении этих целей, если иное не предусмотрено
            федеральным законом.
          </p>

          <p>
            8. Обработка персональных данных в Обществе осуществляется в
            случаях:
          </p>
          <p>
            * обработка персональных данных необходима для исполнения договора,
            стороной которого либо выгодоприобретателем, или поручителем по
            которому является субъект персональных данных, а также для
            заключения договора по инициативе субъекта персональных данных или
            договора, по которому субъект персональных данных будет являться
            выгодоприобретателем или поручителем;
          </p>
          <p>
            * с согласия субъекта персональных данных на обработку его
            персональных данных;
          </p>

          <p>
            * обработка персональных данных необходима для осуществления
            правосудия, исполнения судебного акта, акта другого органа или
            должностного лица, подлежащих исполнению в соответствии с
            законодательством Российской Федерации об исполнительном
            производстве, для осуществления и выполнения возложенных
            законодательством Российской Федерации на Общество функций,
            полномочий и обязанностей;
          </p>
          <p>
            * обработка персональных данных необходима для защиты жизни,
            здоровья или иных жизненно важных интересов субъекта персональных
            данных, если получение согласия субъекта персональных данных
            невозможно;
          </p>

          <p>
            * осуществляется обработка персональных данных, доступ
            неограниченного круга лиц к которым предоставлен субъектом
            персональных данных либо по его просьбе;
          </p>

          <p>
            * осуществляется обработка персональных данных, подлежащих
            опубликованию или обязательному раскрытию в соответствии с
            федеральным законом;
          </p>
          <p>
            * в иных случаях, предусмотренных законодательством Российской
            Федерации.
          </p>
          <p>
            9. Обработка специальных категорий персональных данных, касающихся
            расовой, национальной принадлежности, политических взглядов,
            религиозных или философских убеждений, состояния здоровья, интимной
            жизни, не допускается, за исключением следующих случаев:
          </p>
          <p>
            * субъект персональных данных дал согласие в письменной форме на
            обработку своих персональных данных;
          </p>
          <p>
            * персональные данные сделаны общедоступными субъектом персональных
            данных;
          </p>
          <p>
            * обработка персональных данных осуществляется в соответствии с
            законодательством о государственной социальной помощи, трудовым
            законодательством, пенсионным законодательством Российской
            Федерации;
          </p>
          <p>
            * обработка персональных данных необходима для защиты жизни,
            здоровья или иных жизненно важных интересов других лиц и получение
            согласия субъекта персональных данных невозможно;
          </p>
          <p>
            * обработка персональных данных необходима для осуществления прав
            субъекта персональных данных или третьих лиц, а равно и в связи с
            осуществлением правосудия;
          </p>
          <p>
            * обработка персональных данных осуществляется в соответствии с
            законодательством об отдельных видах страхования, со страховых
            законодательством;
          </p>
          <p>
            * в иных случаях, предусмотренных законодательством Российской
            Федерации.
          </p>
          <p>
            10. Биометрические персональные данные могут обрабатываться в
            Обществе только при наличии согласия в письменной форме субъекта
            персональных данных, за исключением случаев, когда обработка
            биометрических персональных данных осуществляется в связи с
            реализацией международных договоров Российской Федерации о
            реадмиссии, в связи с осуществлением правосудия и исполнением
            судебных актов, а также в случаях, предусмотренных законодательством
            Российской Федерации об обороне, о безопасности, о противодействии
            терроризму, о транспортной безопасности, о противодействии
            коррупции, об оперативно-розыскной деятельности, о государственной
            службе, уголовно-исполнительным законодательством Российской
            Федерации, законодательством Российской Федерации о порядке выезда
            из Российской Федерации и въезда в Российскую Федерацию, о
            гражданстве Российской Федерации.
          </p>
          <p>
            В Обществе не обрабатываются биометрические персональные данные, за
            исключением фотографического изображения субъекта персональных
            данных при соблюдении предусмотренных законодательством условий.
          </p>
          <p>
            11. При сборе персональных данных, в том числе посредством
            информационно-телекоммуникационной сети «Интернет», Общество
            обеспечивает запись, систематизацию, накопление, хранение, уточнение
            (обновление, изменение), извлечение персональных данных граждан
            Российской Федерации с использованием баз данных, находящихся на
            территории Российской Федерации.
          </p>
          <p>
            Трансграничная передача персональных данных в Обществе может
            осуществляться только с ограничениями, предусмотренными действующим
            законодательством.
          </p>

          <p>
            12. В Обществе в целях информационного обеспечения могут
            использоваться общедоступные источники персональных данных (в том
            числе справочники, адресные книги). В общедоступные источники
            персональных данных с письменного согласия субъекта персональных
            данных могут включаться его фамилия, имя, отчество, год и место
            рождения, адрес, абонентский номер, сведения о профессии и иные
            персональные данные, сообщаемые субъектом персональных данных.
          </p>

          <p>
            Сведения о субъекте персональных данных должны быть в любое время
            исключены из общедоступных источников персональных данных по
            требованию субъекта персональных данных либо по решению суда или
            иных уполномоченных органов.
          </p>
          <p>
            13. Общество вправе осуществлять обработку персональных данных по
            поручению другого лица (оператора), если иное не предусмотрено
            федеральным законом, на основании заключаемого с этим лицом
            (оператором) договора, в том числе государственного или
            муниципального контракта, либо на основании принятого
            государственным или муниципальным органом соответствующего акта
            (далее — поручение оператора).
          </p>
          <p>
            Общество, осуществляющее обработку персональных данных по поручению
            оператора, обязано соблюдать принципы и правила обработки
            персональных данных, предусмотренные Федеральным законом от
            27.07.2006 г. № 152-ФЗ «О персональных данных» (далее — Федеральный
            закон «О персональных данных»).
          </p>
          <p>
            В поручении оператора: должны быть определены перечень действий
            (операций) с персональными данными, которые будут совершаться
            Обществом, и цели обработки персональных данных, должна быть
            установлена обязанность Общества соблюдать конфиденциальность
            персональных данных и обеспечивать безопасность персональных данных
            при их обработке; должны быть указаны требования к защите
            обрабатываемых персональных данных в соответствии со статьей 19
            Федерального закона «О персональных данных».
          </p>
          <p>
            14. Общество, осуществляющее обработку персональных данных по
            поручению другого лица, которое является оператором персональных
            данных, не обязано получать согласие субъекта персональных данных на
            обработку его персональных данных.
          </p>
          <p>
            15. В случае, если другое лицо, являющееся оператором персональных
            данных, поручает обработку персональных данных Обществу,
            ответственность перед субъектом персональных данных за действия
            Общества несет данное лицо. Общество несет ответственность перед
            лицом, поручившим Обществу обработку персональных данных.
          </p>
          <p>
            16. При обработке персональных данных Общество обязано обеспечить их
            конфиденциальность, т.е. не раскрывать третьим лицам и не
            распространять персональные данные без согласия субъекта
            персональных данных, если иное не предусмотрено федеральным законом.
          </p>

          <Heading3>IV. Субъекты персональных данных</Heading3>
          <p>
            17. Общество осуществляет обработку персональных данных следующих
            категорий субъектов персональных данных:
          </p>
          <p>
            * работник Общества, соискатель на замещение вакантных должностей
            Общества, бывший работник Общества;
          </p>
          <p>* пользователь услуг (физическое лицо) Общества;</p>
          <p>
            * работник или соискатель на замещение вакантных должностей
            юридического лица (индивидуального предпринимателя), являющегося
            пользователем услуг Общества (работник или кандидат на работу
            контрагента Общества);
          </p>
          <p>
            * работник юридического лица (индивидуального предпринимателя),
            являющегося пользователем услуг Общества (работник контрагента
            Общества) при наличии соответствующего поручения;
          </p>
          <p>
            * любое иное физическое лицо, персональные данные которого стали
            известны Обществу в силу предоставления ему со стороны Общества
            социальных льгот, гарантий и компенсаций.
          </p>
          <Heading3>V. Обрабатываемые персональные данные</Heading3>

          <p>
            {" "}
            18. Общество обрабатывает следующие категории персональных данных:
            фамилия, имя, отчество; число, месяц, год рождения; место рождения;
            адрес места жительства; семейное положение; имущественное положение;
            образование; профессия; индивидуальный номер налогоплательщика;
            данные страхового свидетельства и иные персональные данные,
            обрабатываемые Обществом на основании законодательства Российской
            Федерации.{" "}
          </p>
          <p>
            В Обществе могут обрабатываться также другие персональные данные
            пользователей услуг Общества, необходимые для реализации целей
            обработки, указанных в пункте 6 Политики.
          </p>
          <p>
            19. Персональные данные работников, обрабатываемые в Обществе,
            определяются на основании Трудового кодекса Российской Федерации и
            нормативных актов Общества.
          </p>

          <Heading3>
            VI. Обработка и защита персональных данных в Обществе
          </Heading3>

          <p>
            20. Обработка персональных данных осуществляется с согласия
            субъектов персональных данных, если иное не предусмотрено
            законодательством Российской Федерации.
          </p>

          <p>
            21. Обработка персональных данных, содержащихся в информационной
            системе персональных данных либо извлеченных из такой системы,
            осуществляется с помощью вычислительной техники, однако такие
            действия с персональными данными, как использование, уточнение,
            распространение, уничтожение персональных данных в отношении каждого
            из субъектов персональных данных, осуществляются при
            непосредственном участии человека.
          </p>

          <p>
            22. К обработке персональных данных допускаются только те работники
            Общества, в должностные обязанности которых входит обработка
            персональных данных. Указанные работники имеют право получать только
            те персональные данные, которые необходимы им для выполнения своих
            должностных обязанностей.
          </p>
          <p>23. Обработка персональных данных осуществляется путем:</p>
          <p>
            * получения информации, содержащей персональные данные, в устной и
            письменной форме непосредственно от субъектов персональных данных;
          </p>
          <p>
            * предоставления субъектами персональных данных оригиналов
            необходимых документов;
          </p>
          <p>
            * получения заверенных в установленном порядке копий документов,
            содержащих персональные данные, или копирования оригиналов
            документов;
          </p>
          <p>
            * получения персональных данных при направлении запросов в органы
            государственной власти, государственные внебюджетные фонды, иные
            государственные органы, органы местного самоуправления, коммерческие
            и некоммерческие организации, физическим лицам в случаях и порядке,
            предусмотренных законодательством Российской Федерации;
          </p>
          <p>* получения персональных данных из общедоступных источников;</p>
          <p>
            * фиксации (регистрации) персональных данных в журналах, книгах,
            реестрах и других учетных формах;
          </p>
          <p>
            * внесения персональных данных в информационные системы Общества;
          </p>
          <p>
            * использования иных средств и способов фиксации персональных
            данных, получаемых в рамках осуществляемой Общества деятельности.
          </p>
          <p>
            24. Передача персональных данных третьим лицам допускается с
            письменного согласия субъектов персональных данных, за исключением
            случаев, когда это необходимо в целях предупреждения угрозы жизни и
            здоровью субъектов персональных данных, а также в иных случаях,
            установленных законодательством Российской Федерации.
          </p>
          <p>
            При передаче персональных данных третьим лицам в соответствии с
            заключенными договорами Общество обеспечивает обязательное
            выполнение требований законодательства Российской Федерации и
            нормативных документов Общества в области персональных данных.
          </p>
          <p>
            Передача персональных данных в уполномоченные органы исполнительной
            власти (Федеральную налоговую службу Российской Федерации,
            Пенсионный фонд Российской Федерации, Федеральный фонд обязательного
            медицинского страхования Российской Федерации и др.) осуществляется
            в Обществе в соответствии с требованиями законодательства Российской
            Федерации.
          </p>
          <p>
            25. Хранение персональных данных в Обществе осуществляется в форме,
            позволяющей определить субъекта персональных данных, не дольше, чем
            этого требуют цели их обработки. По достижении целей обработки или в
            случае утраты необходимости в их достижении персональные данные
            подлежат уничтожению.
          </p>
          <p>
            Сроки хранения персональных данных в Обществе определяются в
            соответствии с законодательством Российской Федерации и нормативными
            документами Общества.
          </p>
          <p>
            26. В Обществе запрещено принятие решений, порождающих юридические
            последствия в отношении субъекта персональных данных или иным
            образом затрагивающих его права и законные интересы на основании
            исключительно автоматизированной обработки персональных данных, за
            исключением случаев предусмотренных федеральными законами.
          </p>
          <p>
            27. Общество применяет необходимые и достаточные организационные и
            технические меры по защите персональных данных, включающие в себя
            использование средств защиты информации, обнаружение фактов
            несанкционированного доступа, восстановление персональных данных,
            установление правил доступа к персональным данным, а также контроль
            за принимаемыми мерами по обеспечению безопасности и оценку
            эффективности принимаемых мер.
          </p>
          <p>
            28. Общество осознает необходимость и заинтересовано в обеспечении
            должного как с точки зрения требований нормативных документов
            Российской Федерации, так и обоснованного с точки зрения оценки
            рисков для бизнеса уровня безопасности персональных данных,
            обрабатываемых в рамках выполнения основной деятельности Общества.
          </p>

          <Heading3>VII. Права субъектов персональных данных</Heading3>
          <p>29. Субъект персональных данных имеет право:</p>
          <p>
            * получать в соответствии с законодательством Российской Федерации
            информацию, касающуюся обработки в Обществе его персональных данных,
            за исключением случаев, предусмотренных законодательством Российской
            Федерации;
          </p>
          <p>
            * требовать исправления неверных либо неполных персональных данных,
            а также данных, обрабатываемых с нарушением требований
            законодательства Российской Федерации;
          </p>
          <p>
            * требовать блокирования или уничтожения своих персональных данных в
            случае, если персональные данные являются неполными, устаревшими,
            неточными, незаконно полученными или не являются необходимыми для
            заявленной цели обработки;
          </p>

          <p>
            * требовать извещения всех лиц, которым ранее были сообщены его
            неверные или неполные персональные данные, обо всех произведенных в
            них изменениях;
          </p>
          <p>* отозвать согласие на обработку своих персональных данных;</p>
          <p>
            * обжаловать действия или бездействие Общества при обработке своих
            персональных данных в соответствии с законодательством Российской
            Федерации;
          </p>
          <p>
            * осуществлять иные права, предусмотренные законодательством
            Российской Федерации.
          </p>
          <Heading3>
            VIII. Обязанности Общества, как оператора персональных данных
          </Heading3>
          <p>30. Общество при обработке персональных данных обязано:</p>
          <p>
            * принимать необходимые меры для выполнения обязанностей оператора,
            предусмотренных законодательством Российской Федерации в сфере
            обработки и защиты персональных данных;
          </p>
          <p>
            * разъяснять субъекту персональных данных юридические последствия
            отказа предоставить персональные данные, если это является
            обязательным в соответствии с законодательством Российской
            Федерации;
          </p>
          <p>
            * осуществлять блокирование неправомерно обрабатываемых персональных
            данных;
          </p>
          <p>
            * осуществлять прекращение обработки персональных данных в
            соответствии с законодательством Российской Федерации;
          </p>
          <p>
            * уведомлять субъекта персональных данных об устранении допущенных
            нарушений или уничтожении его персональных данных;
          </p>
          <p>
            * предоставлять по просьбе субъекта персональных данных или его
            представителя информацию, касающуюся обработки его персональных
            данных, в порядке, установленном законодательством Российской
            Федерации и нормативными актами Общества.
          </p>
          <p>
            31. В целях принятия мер, необходимых для выполнения обязанностей,
            предусмотренных законодательством Российской Федерации и
            нормативными документами Общества, приказом Генерального директора
            Общества назначается лицо, ответственное за организацию обработки
            персональных данных в Обществе.
          </p>
          <p>
            32. Лицо, ответственное за организацию обработки персональных данных
            в Обществе, получает указания непосредственно от Генерального
            директора Общества и подотчетно ему.
          </p>
          <p>
            33. Лицо, ответственное за организацию обработки персональных данных
            в Обществе, в частности, обязано:
          </p>
          <p>
            * организовывать принятие правовых, организационных и технических
            мер для обеспечения защиты персональных данных, обрабатываемых в
            Обществе, от неправомерного или случайного доступа к ним,
            уничтожения, изменения, блокирования, копирования, распространения
            персональных данных, а также от иных неправомерных действий в
            отношении персональных данных;
          </p>
          <p>
            * организовывать осуществление внутреннего контроля за соблюдением в
            Обществе требований законодательства Российской Федерации и
            нормативных документов Общества, в области персональных данных, в
            том числе требований к защите персональных данных;
          </p>
          <p>
            * организовывать доведение до сведения работников Общества положений
            законодательства Российской Федерации о персональных данных,
            нормативных документов Общества по вопросам обработки персональных
            данных, а также требований к защите персональных данных;
          </p>
          <p>
            * организовывать прием и обработку обращений и запросов субъектов
            персональных данных или их представителей, а также осуществлять
            контроль за приемом и обработкой таких обращений в Обществе.
          </p>

          <Heading3>
            IX. Ответственность за нарушение требований по обработке и защите
            персональных данных
          </Heading3>

          <p>
            34. Ответственность за нарушение требований законодательства
            Российской Федерации и нормативных актов Общества в сфере обработки
            и защиты персональных данных определяется в соответствии с
            законодательством Российской Федерации.
          </p>
        </StyledContainer>
      </Root>
    </MainLayout>
  );
};

const Root = ({ children }) => {
  return <section>{children}</section>;
};

const StyledContainer = styled(Container)`
  padding-top: 128px;
  padding-bottom: 120px;
  @media (max-width: 768px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;

const Title = styled.h1`
  ${TYPOGRAPHY.title1Bold64};
  text-align: center;
  color: ${COLORS.primaryDarkBlue};
  padding-bottom: 80px;
  @media (max-width: 768px) {
    text-align: left;
    padding-bottom: 16px;
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.title3Bold28};
  }
`;

const Heading3 = styled.h3`
  ${TYPOGRAPHY.title3Bold28};
  color: ${COLORS.grayscaleDarkmode};
  padding-top: 80px;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    color: ${COLORS.primaryDarkBlue};
    padding-top: 64px;
    padding-bottom: 16px;
    ${TYPOGRAPHY.bodyReg16M};
  }
`;

export default Policy;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
